import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer,private iconReg:SvgIconRegistryService) {
    this.registerIcons();
  }
  
   registerIcons(): void {
    const icons = [
      'Add-Note',
      'Analyze',
      'Auto-Payments',
      'Back',
      'Back2',
      'Bank',
      'calendar',
      'Car-Linear',
      'Call',
      'Camera',
      'Caption',
      'Car',
      'Card',
      'Certificate',
      'Checklist',
      'Cheque',
      'Circle-Minus',
      'Circle-Plus',
      'Close-V2',
      'Close',
      'Comment',
      'Community',
      'Dashboard-custom',
      'Debit-Card',
      'degree-hat',
      'Delete',
      'Delete2',
      'Document',
      'Download',
      'Draft',
      'Drag',
      'Dropdown',
      'Duplicate',
      'Edit',
      'Edit2',
      'Education',
      'Emoji',
      'Eye',
      'EyeFilled',
      'File2',
      'Filter',
      'Fingerprint',
      'Flash',
      'Food',
      'Fuel',
      'Gift',
      'Goal',
      'Grocery',
      'Growth',
      'Health',
      'help',
      'Home-2',
      'home',
      'Image',
      'Info',
      'Integration',
      'Like-filled',
      'Like',
      'Link',
      'Location-Fill',
      'Log-off',
      'Mail-fill',
      'Mail',
      "down",
      'Maximize',
      'Message',
      'Minus',
      'Movie',
      'Mute',
      'nav-down',
      'nav-left',
      'nav-right',
      'nav-up',
      "Nearby-ATM's",
      'Next',
      'Note-Added',
      'Note-Not-Added',
      'Notification',
      'Notifications',
      'Offers',
      'option',
      'Package',
      'Password',
      'Pause-1',
      'Plane',
      'Play-Arrow-1',
      'play-button',
      'Plus',
      'Privacy-Policy',
      'Profit',
      'Question',
      'Quote',
      'Redirect',
      'Reset',
      'Restart',
      'Scan',
      'Schedule',
      'Search',
      'Settings',
      'Share-2',
      'Share',
      'Shopping',
      'Star-Linear',
      'Star',
      'Status',
      'success-filled',
      'Support',
      'Task',
      'Terms',
      'Tick',
      'Time',
      'Unknown',
      'Unlocked',
      'Upload',
      'User-2',
      'User-3',
      'User-avatar',
      'User',
      'User2',
      'Vector',
      'Work',
      'Workflow',
      'Workspace',
      'zoom-in',
      'zoom-out'
      // Add all your icon names here
    ];
     
     icons.forEach(icon => {
      this.iconRegistry.addSvgIcon(
        `${icon}`,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/custom-icons/${icon}.svg`)
      );
    });
  }
}
