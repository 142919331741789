import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, catchError, tap, finalize } from 'rxjs';
import { ErrorService } from '../../shared/services/error.service';
import { LoaderService } from '../../shared/services/loader.service';
import { MsalService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ServerHttpInterceptor implements HttpInterceptor {
  private errorService = inject(ErrorService);
  private loaderService = inject(LoaderService);
  private msalService = inject(MsalService);
  private snackBar = inject(MatSnackBar);

  // TODO: Move this into service if it is needed
  openErrorSnackbar(error: HttpErrorResponse) {
    if (error.status === 0 || (error.status >= 400 && error.status < 600)) {
      this.snackBar.open(
        error?.error?.description ||
          error?.error?.title ||
          error?.error?.message ||
          error?.message,
        'Close'
      );
      // HINT maybe this will be needed in future
      // setTimeout(() => {
      //   this.snackBar.dismiss();
      // }, 10000);
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          switch (event.status) {
            case 200: {
              if (event.url?.includes('/Users/Upload')) {
                this.errorService.clearValidationServerErrors();
                this.errorService.publishWarningErrors(null);
                if (event.body.warnings.length) {
                  const validationErrors = [];
                  for (const value of Object.values(event.body.warnings)) {
                    if (Array.isArray(value)) {
                      validationErrors.push(...value);
                    } else {
                      validationErrors.push(value);
                    }
                  }
                  this.errorService.publishWarningErrors(validationErrors);
                }
                if (event.body.errors) {
                  this.errorService.publishValidationServerErrors(
                    event.body.errors[0]
                  );
                }
              }
              break;
            }
            case 201: {
              console.log('Resource created successfully with status 201');
              break;
            }
            default: {
              break;
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.loaderService.showLoader(); // Show the loader in case of server not reachable
        }
        this.openErrorSnackbar(error);
        switch (error.status) {
          case 401: {
            break;
          }

          case 400: {
            this.errorService.publishValidationServerErrors([
              error.error.description.split('.')[0] + '.',
            ]);
            break;
          }
          case 404: {
            break;
          }
          case 409: {
            this.errorService.publishValidationServerErrors(error.error.detail);
            break;
          }
          case 418: {
            this.msalService.instance.logoutPopup();
            break;
          }
          case 500: {
            this.errorService.publishInternalServerError(
              'Oops... Something went wrong!'
            );
            break;
          }
          case 504: {
            break;
          }
          default: {
            const errorMessage = 'Server is not reachable, please try again.';
            break;
          }
        }
        return throwError(() => error);
      }),
      finalize(() => {
        // this.loaderService.hideLoader();
      })
    );
  }
}
