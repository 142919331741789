/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateAttemptCommand } from '../model/createAttemptCommand';
import { GetAttemptProgressResultResponse } from '../model/getAttemptProgressResultResponse';
import { GetCompletionRecordsResultResponse } from '../model/getCompletionRecordsResultResponse';
import { GetExperienceStructureAndAttemptsResultResponse } from '../model/getExperienceStructureAndAttemptsResultResponse';
import { GetMistakesResultResponse } from '../model/getMistakesResultResponse';
import { LevelType } from '../model/levelType';
import { UpdateAttemptCommand } from '../model/updateAttemptCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TrainingRecordsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsAttemptPost(body?: CreateAttemptCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public trainingRecordsAttemptPost(body?: CreateAttemptCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public trainingRecordsAttemptPost(body?: CreateAttemptCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public trainingRecordsAttemptPost(body?: CreateAttemptCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/TrainingRecords/Attempt`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param level 
     * @param levelId 
     * @param experienceIds 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsAttemptProgressGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<GetAttemptProgressResultResponse>;
    public trainingRecordsAttemptProgressGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAttemptProgressResultResponse>>;
    public trainingRecordsAttemptProgressGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAttemptProgressResultResponse>>;
    public trainingRecordsAttemptProgressGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (level === null || level === undefined) {
            throw new Error('Required parameter level was null or undefined when calling trainingRecordsAttemptProgressGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (level !== undefined && level !== null) {
            queryParameters = queryParameters.set('Level', <any>level);
        }
        if (levelId !== undefined && levelId !== null) {
            queryParameters = queryParameters.set('LevelId', <any>levelId);
        }
        if (experienceIds) {
            experienceIds.forEach((element) => {
                queryParameters = queryParameters.append('ExperienceIds', <any>element);
            })
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetAttemptProgressResultResponse>('get',`${this.basePath}/TrainingRecords/AttemptProgress`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param level 
     * @param levelId 
     * @param experienceIds 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsCompletionRecordsGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<GetCompletionRecordsResultResponse>;
    public trainingRecordsCompletionRecordsGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCompletionRecordsResultResponse>>;
    public trainingRecordsCompletionRecordsGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCompletionRecordsResultResponse>>;
    public trainingRecordsCompletionRecordsGet(level: LevelType, levelId?: string, experienceIds?: Array<string>, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (level === null || level === undefined) {
            throw new Error('Required parameter level was null or undefined when calling trainingRecordsCompletionRecordsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (level !== undefined && level !== null) {
            queryParameters = queryParameters.set('Level', <any>level);
        }
        if (levelId !== undefined && levelId !== null) {
            queryParameters = queryParameters.set('LevelId', <any>levelId);
        }
        if (experienceIds) {
            experienceIds.forEach((element) => {
                queryParameters = queryParameters.append('ExperienceIds', <any>element);
            })
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetCompletionRecordsResultResponse>('get',`${this.basePath}/TrainingRecords/CompletionRecords`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param experienceId 
     * @param level 
     * @param levelId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsExperienceStructureAndAttemptsGet(experienceId: string, level?: LevelType, levelId?: string, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<GetExperienceStructureAndAttemptsResultResponse>;
    public trainingRecordsExperienceStructureAndAttemptsGet(experienceId: string, level?: LevelType, levelId?: string, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExperienceStructureAndAttemptsResultResponse>>;
    public trainingRecordsExperienceStructureAndAttemptsGet(experienceId: string, level?: LevelType, levelId?: string, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExperienceStructureAndAttemptsResultResponse>>;
    public trainingRecordsExperienceStructureAndAttemptsGet(experienceId: string, level?: LevelType, levelId?: string, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (experienceId === null || experienceId === undefined) {
            throw new Error('Required parameter experienceId was null or undefined when calling trainingRecordsExperienceStructureAndAttemptsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (level !== undefined && level !== null) {
            queryParameters = queryParameters.set('Level', <any>level);
        }
        if (levelId !== undefined && levelId !== null) {
            queryParameters = queryParameters.set('LevelId', <any>levelId);
        }
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExperienceStructureAndAttemptsResultResponse>('get',`${this.basePath}/TrainingRecords/ExperienceStructureAndAttempts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param levelType 
     * @param levelId 
     * @param experienceId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsGetMistakesGet(levelType?: LevelType, levelId?: string, experienceId?: string, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<GetMistakesResultResponse>;
    public trainingRecordsGetMistakesGet(levelType?: LevelType, levelId?: string, experienceId?: string, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetMistakesResultResponse>>;
    public trainingRecordsGetMistakesGet(levelType?: LevelType, levelId?: string, experienceId?: string, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetMistakesResultResponse>>;
    public trainingRecordsGetMistakesGet(levelType?: LevelType, levelId?: string, experienceId?: string, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (levelType !== undefined && levelType !== null) {
            queryParameters = queryParameters.set('LevelType', <any>levelType);
        }
        if (levelId !== undefined && levelId !== null) {
            queryParameters = queryParameters.set('LevelId', <any>levelId);
        }
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('StartDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetMistakesResultResponse>('get',`${this.basePath}/TrainingRecords/GetMistakes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param level 
     * @param guid 
     * @param isWeeklyAverageTime 
     * @param isMonthlyActiveUsers 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsGetTrainingProgressChartDataGet(level: LevelType, guid: string, isWeeklyAverageTime: boolean, isMonthlyActiveUsers: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public trainingRecordsGetTrainingProgressChartDataGet(level: LevelType, guid: string, isWeeklyAverageTime: boolean, isMonthlyActiveUsers: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public trainingRecordsGetTrainingProgressChartDataGet(level: LevelType, guid: string, isWeeklyAverageTime: boolean, isMonthlyActiveUsers: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public trainingRecordsGetTrainingProgressChartDataGet(level: LevelType, guid: string, isWeeklyAverageTime: boolean, isMonthlyActiveUsers: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (level === null || level === undefined) {
            throw new Error('Required parameter level was null or undefined when calling trainingRecordsGetTrainingProgressChartDataGet.');
        }

        if (guid === null || guid === undefined) {
            throw new Error('Required parameter guid was null or undefined when calling trainingRecordsGetTrainingProgressChartDataGet.');
        }

        if (isWeeklyAverageTime === null || isWeeklyAverageTime === undefined) {
            throw new Error('Required parameter isWeeklyAverageTime was null or undefined when calling trainingRecordsGetTrainingProgressChartDataGet.');
        }

        if (isMonthlyActiveUsers === null || isMonthlyActiveUsers === undefined) {
            throw new Error('Required parameter isMonthlyActiveUsers was null or undefined when calling trainingRecordsGetTrainingProgressChartDataGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (level !== undefined && level !== null) {
            queryParameters = queryParameters.set('Level', <any>level);
        }
        if (guid !== undefined && guid !== null) {
            queryParameters = queryParameters.set('Guid', <any>guid);
        }
        if (isWeeklyAverageTime !== undefined && isWeeklyAverageTime !== null) {
            queryParameters = queryParameters.set('IsWeeklyAverageTime', <any>isWeeklyAverageTime);
        }
        if (isMonthlyActiveUsers !== undefined && isMonthlyActiveUsers !== null) {
            queryParameters = queryParameters.set('IsMonthlyActiveUsers', <any>isMonthlyActiveUsers);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/TrainingRecords/GetTrainingProgressChartData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trainingRecordsIdAttemptPut(id: string, body?: UpdateAttemptCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public trainingRecordsIdAttemptPut(id: string, body?: UpdateAttemptCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public trainingRecordsIdAttemptPut(id: string, body?: UpdateAttemptCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public trainingRecordsIdAttemptPut(id: string, body?: UpdateAttemptCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling trainingRecordsIdAttemptPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/TrainingRecords/${encodeURIComponent(String(id))}/Attempt`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
