/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MeasureType = 'Unknown' | 'Attempts' | 'AttemptCompletions' | 'TaskCompletions' | 'ModuleCompletions' | 'Sessions';

export const MeasureType = {
    Unknown: 'Unknown' as MeasureType,
    Attempts: 'Attempts' as MeasureType,
    AttemptCompletions: 'AttemptCompletions' as MeasureType,
    TaskCompletions: 'TaskCompletions' as MeasureType,
    ModuleCompletions: 'ModuleCompletions' as MeasureType,
    Sessions: 'Sessions' as MeasureType
};