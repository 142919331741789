import { RouteConstants } from './route.constants';

export const adminAccess = [
  RouteConstants.DASHBOARD,
  RouteConstants.USER_MANAGEMENT,
  RouteConstants.STAFF,
  RouteConstants.COHORT_MANAGEMENT,
  RouteConstants.SQUADRON_MANAGEMENT,
  RouteConstants.TRAINING_MANAGEMENT,
  RouteConstants.DOWNLOAD_EXPERIENCE,
  RouteConstants.WING_MANAGEMENT,
];
export const staffAccess = [
  RouteConstants.DASHBOARD,
  RouteConstants.USER_MANAGEMENT,
  RouteConstants.STAFF,
  RouteConstants.COHORT_MANAGEMENT,
  RouteConstants.SQUADRON_MANAGEMENT,
  RouteConstants.DOWNLOAD_EXPERIENCE,
];
export const financialDelegateAccess = [
  RouteConstants.LICENCE_MANAGEMENT,
  RouteConstants.APPROVALS,
  RouteConstants.INVOICES,
];
export const cadetAccess = [
  RouteConstants.DASHBOARD,
  RouteConstants.STAFF,
  RouteConstants.COHORT_MANAGEMENT,
  RouteConstants.DOWNLOAD_EXPERIENCE,
];
