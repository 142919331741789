/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateUserCommand } from '../model/createUserCommand';
import { GetUserResult } from '../model/getUserResult';
import { GetUsersResult } from '../model/getUsersResult';
import { RoleType } from '../model/roleType';
import { Tiers } from '../model/tiers';
import { UpdateUserCommand } from '../model/updateUserCommand';
import { UpdateUserResult } from '../model/updateUserResult';
import { UploadUsersResponse } from '../model/uploadUsersResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param roleType 
     * @param pageNumber 
     * @param pageSize 
     * @param sort 
     * @param licenceTier 
     * @param unlicenced 
     * @param filterOnline 
     * @param squadronId 
     * @param wingId 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGet(roleType: RoleType, pageNumber?: number, pageSize?: number, sort?: string, licenceTier?: Tiers, unlicenced?: boolean, filterOnline?: boolean, squadronId?: string, wingId?: string, search?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUsersResult>;
    public usersGet(roleType: RoleType, pageNumber?: number, pageSize?: number, sort?: string, licenceTier?: Tiers, unlicenced?: boolean, filterOnline?: boolean, squadronId?: string, wingId?: string, search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUsersResult>>;
    public usersGet(roleType: RoleType, pageNumber?: number, pageSize?: number, sort?: string, licenceTier?: Tiers, unlicenced?: boolean, filterOnline?: boolean, squadronId?: string, wingId?: string, search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUsersResult>>;
    public usersGet(roleType: RoleType, pageNumber?: number, pageSize?: number, sort?: string, licenceTier?: Tiers, unlicenced?: boolean, filterOnline?: boolean, squadronId?: string, wingId?: string, search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleType === null || roleType === undefined) {
            throw new Error('Required parameter roleType was null or undefined when calling usersGet.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (roleType !== undefined && roleType !== null) {
            queryParameters = queryParameters.set('RoleType', <any>roleType);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('Sort', <any>sort);
        }
        if (licenceTier !== undefined && licenceTier !== null) {
            queryParameters = queryParameters.set('LicenceTier', <any>licenceTier);
        }
        if (unlicenced !== undefined && unlicenced !== null) {
            queryParameters = queryParameters.set('Unlicenced', <any>unlicenced);
        }
        if (filterOnline !== undefined && filterOnline !== null) {
            queryParameters = queryParameters.set('FilterOnline', <any>filterOnline);
        }
        if (squadronId !== undefined && squadronId !== null) {
            queryParameters = queryParameters.set('SquadronId', <any>squadronId);
        }
        if (wingId !== undefined && wingId !== null) {
            queryParameters = queryParameters.set('WingId', <any>wingId);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('Search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUsersResult>('get',`${this.basePath}/Users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersPost(body?: CreateUserCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersPost(body?: CreateUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersPost(body?: CreateUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersPost(body?: CreateUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/Users/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserResult>;
    public usersUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserResult>>;
    public usersUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserResult>>;
    public usersUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserResult>('get',`${this.basePath}/Users/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidPut(uid: string, body?: UpdateUserCommand, observe?: 'body', reportProgress?: boolean): Observable<UpdateUserResult>;
    public usersUidPut(uid: string, body?: UpdateUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateUserResult>>;
    public usersUidPut(uid: string, body?: UpdateUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateUserResult>>;
    public usersUidPut(uid: string, body?: UpdateUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateUserResult>('put',`${this.basePath}/Users/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUploadPostForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadUsersResponse>;
    public usersUploadPostForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadUsersResponse>>;
    public usersUploadPostForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadUsersResponse>>;
    public usersUploadPostForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<UploadUsersResponse>('post',`${this.basePath}/Users/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
