export class RouteConstants {
  static readonly DASHBOARD = 'dashboard';
  static readonly USER_MANAGEMENT = 'user-management';
  static readonly STAFF = 'staff';
  static readonly LICENCE_MANAGEMENT = 'licence-management';
  static readonly APPROVALS = 'approvals';
  static readonly COHORT_MANAGEMENT = 'cohort-management';
  static readonly SQUADRON_MANAGEMENT = 'squadron-management';
  static readonly WING_MANAGEMENT = 'wing-management';
  static readonly TRAINING_MANAGEMENT = 'training-management';
  static readonly INVOICES = 'invoices';
  static readonly DOWNLOAD_EXPERIENCE = 'download-experience';
  static readonly NOTIFICATIONS = 'notifications';
  static readonly EXPERIENCE = 'experience';
  static readonly TRAINING_PROGRAM='training-program'
}
