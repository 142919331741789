/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RoleType = 'Unknown' | 'Admin' | 'FinancialDelegates' | 'Staff' | 'Cadet';

export const RoleType = {
    Unknown: 'Unknown' as RoleType,
    Admin: 'Admin' as RoleType,
    FinancialDelegates: 'FinancialDelegates' as RoleType,
    Staff: 'Staff' as RoleType,
    Cadet: 'Cadet' as RoleType
};