/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ErrorType = 'Failure' | 'Unexpected' | 'Validation' | 'Conflict' | 'NotFound' | 'Unauthorized' | 'Forbidden';

export const ErrorType = {
    Failure: 'Failure' as ErrorType,
    Unexpected: 'Unexpected' as ErrorType,
    Validation: 'Validation' as ErrorType,
    Conflict: 'Conflict' as ErrorType,
    NotFound: 'NotFound' as ErrorType,
    Unauthorized: 'Unauthorized' as ErrorType,
    Forbidden: 'Forbidden' as ErrorType
};