/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LearningSupportElementType = 'Unknown' | 'TextPanel' | 'ForwardAndBackPanel' | 'QuestionPanel' | 'PicturePanel' | 'VideoPanel' | 'DocumentPanel' | 'WebContentPanel' | 'Recording3D' | 'Object3D' | 'AiAvatar' | 'Location' | 'FreehandDrawing' | 'CAD' | 'CustomElement';

export const LearningSupportElementType = {
    Unknown: 'Unknown' as LearningSupportElementType,
    TextPanel: 'TextPanel' as LearningSupportElementType,
    ForwardAndBackPanel: 'ForwardAndBackPanel' as LearningSupportElementType,
    QuestionPanel: 'QuestionPanel' as LearningSupportElementType,
    PicturePanel: 'PicturePanel' as LearningSupportElementType,
    VideoPanel: 'VideoPanel' as LearningSupportElementType,
    DocumentPanel: 'DocumentPanel' as LearningSupportElementType,
    WebContentPanel: 'WebContentPanel' as LearningSupportElementType,
    Recording3D: 'Recording3D' as LearningSupportElementType,
    Object3D: 'Object3D' as LearningSupportElementType,
    AiAvatar: 'AiAvatar' as LearningSupportElementType,
    Location: 'Location' as LearningSupportElementType,
    FreehandDrawing: 'FreehandDrawing' as LearningSupportElementType,
    CAD: 'CAD' as LearningSupportElementType,
    CustomElement: 'CustomElement' as LearningSupportElementType
};