/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateExperienceCommand } from '../model/createExperienceCommand';
import { CreateExperienceResult } from '../model/createExperienceResult';
import { DeleteExperienceCommand } from '../model/deleteExperienceCommand';
import { ExperienceModel } from '../model/experienceModel';
import { GetExperienceStructureResultResponse } from '../model/getExperienceStructureResultResponse';
import { GetExperienceTreeResultResponse } from '../model/getExperienceTreeResultResponse';
import { GetExperiencesResult } from '../model/getExperiencesResult';
import { UpdateExperienceCommand } from '../model/updateExperienceCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExperiencesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesCreatePost(body?: CreateExperienceCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateExperienceResult>;
    public experiencesCreatePost(body?: CreateExperienceCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateExperienceResult>>;
    public experiencesCreatePost(body?: CreateExperienceCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateExperienceResult>>;
    public experiencesCreatePost(body?: CreateExperienceCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateExperienceResult>('post',`${this.basePath}/Experiences/Create`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesDeleteDelete(body?: DeleteExperienceCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experiencesDeleteDelete(body?: DeleteExperienceCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experiencesDeleteDelete(body?: DeleteExperienceCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experiencesDeleteDelete(body?: DeleteExperienceCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Experiences/Delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param experienceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesExperienceStructureGet(experienceId: string, observe?: 'body', reportProgress?: boolean): Observable<GetExperienceStructureResultResponse>;
    public experiencesExperienceStructureGet(experienceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExperienceStructureResultResponse>>;
    public experiencesExperienceStructureGet(experienceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExperienceStructureResultResponse>>;
    public experiencesExperienceStructureGet(experienceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (experienceId === null || experienceId === undefined) {
            throw new Error('Required parameter experienceId was null or undefined when calling experiencesExperienceStructureGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExperienceStructureResultResponse>('get',`${this.basePath}/Experiences/ExperienceStructure`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param experienceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesExperienceTreeGet(experienceId: string, observe?: 'body', reportProgress?: boolean): Observable<GetExperienceTreeResultResponse>;
    public experiencesExperienceTreeGet(experienceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExperienceTreeResultResponse>>;
    public experiencesExperienceTreeGet(experienceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExperienceTreeResultResponse>>;
    public experiencesExperienceTreeGet(experienceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (experienceId === null || experienceId === undefined) {
            throw new Error('Required parameter experienceId was null or undefined when calling experiencesExperienceTreeGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (experienceId !== undefined && experienceId !== null) {
            queryParameters = queryParameters.set('ExperienceId', <any>experienceId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExperienceTreeResultResponse>('get',`${this.basePath}/Experiences/ExperienceTree`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesGet(observe?: 'body', reportProgress?: boolean): Observable<GetExperiencesResult>;
    public experiencesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExperiencesResult>>;
    public experiencesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExperiencesResult>>;
    public experiencesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExperiencesResult>('get',`${this.basePath}/Experiences`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pageNumber 
     * @param pageSize 
     * @param sort 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesGetAllExperiencesGet(pageNumber?: number, pageSize?: number, sort?: string, search?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experiencesGetAllExperiencesGet(pageNumber?: number, pageSize?: number, sort?: string, search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experiencesGetAllExperiencesGet(pageNumber?: number, pageSize?: number, sort?: string, search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experiencesGetAllExperiencesGet(pageNumber?: number, pageSize?: number, sort?: string, search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('Sort', <any>sort);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('Search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/Experiences/GetAllExperiences`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesGetSingleGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ExperienceModel>;
    public experiencesGetSingleGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExperienceModel>>;
    public experiencesGetSingleGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExperienceModel>>;
    public experiencesGetSingleGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling experiencesGetSingleGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('Id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExperienceModel>('get',`${this.basePath}/Experiences/GetSingle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public experiencesUpdatePut(body?: UpdateExperienceCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public experiencesUpdatePut(body?: UpdateExperienceCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public experiencesUpdatePut(body?: UpdateExperienceCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public experiencesUpdatePut(body?: UpdateExperienceCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Experiences/Update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
