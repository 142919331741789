/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CompletionLevelType = 'Unknown' | 'Module' | 'Task' | 'Action';

export const CompletionLevelType = {
    Unknown: 'Unknown' as CompletionLevelType,
    Module: 'Module' as CompletionLevelType,
    Task: 'Task' as CompletionLevelType,
    Action: 'Action' as CompletionLevelType
};