/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type QuestionType = 'Unknown' | 'MultipleChoice' | 'ShortText' | 'LongText' | 'VoiceAnswer';

export const QuestionType = {
    Unknown: 'Unknown' as QuestionType,
    MultipleChoice: 'MultipleChoice' as QuestionType,
    ShortText: 'ShortText' as QuestionType,
    LongText: 'LongText' as QuestionType,
    VoiceAnswer: 'VoiceAnswer' as QuestionType
};