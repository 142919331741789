import { inject, Injectable, signal } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import {
  adminAccess,
  staffAccess,
  financialDelegateAccess,
  cadetAccess,
} from '../constants/roleAccess.constants';
import { MsalService } from '@azure/msal-angular';
import { RoleType, UserModel } from '@client-app/core-api';
import { Observable, of } from 'rxjs';
import { RouteConstants } from '../constants/route.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: AccountInfo | undefined;
  userDetails = signal<UserModel | undefined>(undefined);
  msalService = inject(MsalService);

  accessInfo: Record<RoleType, string[]> = {
    Unknown: [],
    Admin: adminAccess,
    FinancialDelegates: financialDelegateAccess,
    Staff: staffAccess,
    Cadet: cadetAccess,
  };

  getAccessibleRoutes(): Observable<any> {
    const roles = this.getUserRoles();
    if (!roles) return of(undefined);

    const allAccessValues = roles
      .flatMap((role) => this.accessInfo[role] || []) // Flatten arrays into one array
      .reduce((uniqueValues, value) => {
        uniqueValues.add(value); // Add each value to a Set to ensure uniqueness
        return uniqueValues;
      }, new Set<string>()); // Initialize Set with type string

    // Convert Set back to an array
    const uniqueAccessArray = Array.from(allAccessValues);
    const sortedArray =
      this.sortInSidebarNavigationButtonsOrder(uniqueAccessArray);

    return of(sortedArray);
  }

  sortInSidebarNavigationButtonsOrder(uniqueAccessArray: any[]) {
    const navigationOrder = Object.values(RouteConstants);

    // Sort the array by predefined order
    const sortedValues = uniqueAccessArray.sort((a, b) => {
      return navigationOrder.indexOf(a) - navigationOrder.indexOf(b);
    });

    return sortedValues;
  }

  getUserRoles(): RoleType[] | undefined {
    const roles =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    if (!roles?.length) return undefined;

    const roleMap = Object.fromEntries(
      Object.entries(environment.roles).map(([key, value]) => [value, key])
    );

    // Collect all matching role names
    const matchingRoleNames = roles
      .map((roleValue) => roleMap[roleValue])
      .filter((roleName): roleName is string => !!roleName);
    return this.roleTypeArray(matchingRoleNames);
  }

  roleTypeArray(roles: string[]): RoleType[] {
    const roleMapArray = roles.map((role) => {
      switch (role.toLowerCase()) {
        case 'admin':
          return RoleType.Admin;
        case 'financialdelegate':
          return RoleType.FinancialDelegates;
        case 'staff':
          return RoleType.Staff;
        case 'cadet':
          return RoleType.Cadet;
        default:
          return RoleType.Unknown;
      }
    });
    return roleMapArray;
  }

  setUserDetails(userDetails: UserModel) {
    return this.userDetails.set(userDetails);
  }
  get getUserDetails(): UserModel | undefined {
    return this.userDetails();
  }

  setUserInfo(user: AccountInfo) {
    return (this.user = user);
  }
  get getUserInfo(): AccountInfo | undefined {
    return this.user;
  }

  userIsCadet() {
    const roles = this.getUserRoles();
    return roles?.length === 1 && roles[0] === RoleType.Cadet;
  }

  hasRequiredRoles(requiredRoles: RoleType[]): boolean {
    const userRoles = this.getUserRoles();
    if (!userRoles) return false;

    const userDetails = this.userDetails();
    // If user has 'Admin' or 'FinancialDelegates' role, they can see the buttons without further checks
    if (
      userRoles.includes(RoleType.Admin) ||
      userRoles.includes(RoleType.FinancialDelegates)
    ) {
      return true;
    }

    // If the user only has 'Staff' role, check for squadron or wing
    if (
      userRoles.includes(RoleType.Staff) &&
      !(userDetails?.squadron?.uid || userDetails?.wing?.id)
    ) {
      return false;
    }

    // Otherwise, check if userRoles contain any of the requiredRoles
    return requiredRoles.some((role) => userRoles.includes(role));
  }
}
