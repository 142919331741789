import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  //TODO: CHANGE TO SIGNAL

  _isErrorThrown = signal<boolean>(false);
  _notFoundError = signal<string>('');
  _internalServerError = signal<string>('');
  _notReachableError = signal<string>('');
  _validationErrors = signal<any | null>(null);
  _warningErrors = signal<string[] | null>(null);

  publishValidationServerErrors(error: string[] | null) {
    this._validationErrors.set(error);
  }

  publishWarningErrors(error: string[] | null) {
    this._warningErrors.set(error);
  }

  publishNotFoundServerError(error: string) {
    this._notFoundError.set(error);
  }

  publishInternalServerError(error: string) {
    this._internalServerError.update(() => error);
  }

  publishNotReachableServerError(error: string) {
    this._notReachableError.update(() => error);
  }

  clearServerErrors() {
    this._isErrorThrown.set(false);
    this._notFoundError.set('');
    this._internalServerError.set('');
    this._notReachableError.set('');
  }

  clearValidationServerErrors() {
    this._validationErrors.set(null);
  }
}
