import { inject, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { RoleType } from '@client-app/core-api';


@Pipe({
  name: 'hasRequiredRoles',
  standalone:true
})
export class HasRequiredRolesPipe implements PipeTransform {
  private authService=inject(AuthService)

  transform(requiredRoles: RoleType[]): boolean {
    const userRoles = this.authService.getUserRoles();
    if (!userRoles) return false;

    const userDetails = this.authService.userDetails();  
    // If user has 'Admin' or 'FinancialDelegates' role, they can see the buttons without further checks
    if (userRoles.includes(RoleType.Admin) || userRoles.includes(RoleType.FinancialDelegates)) {
      return true;
    }

    // If the user only has 'Staff' role, check for squadron or wing
    if (userRoles.includes(RoleType.Staff) && !(userDetails?.squadron?.uid || userDetails?.wing?.id)) {
      return false;
    }

    // Otherwise, check if userRoles contain any of the requiredRoles
    return requiredRoles.some(role => userRoles.includes(role));
  }
}
