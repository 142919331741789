/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LevelType = 'Unknown' | 'Student' | 'Cohort' | 'Squadron' | 'Wing' | 'All';

export const LevelType = {
    Unknown: 'Unknown' as LevelType,
    Student: 'Student' as LevelType,
    Cohort: 'Cohort' as LevelType,
    Squadron: 'Squadron' as LevelType,
    Wing: 'Wing' as LevelType,
    All: 'All' as LevelType
};