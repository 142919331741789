import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const sanitizedRoute = state.url.split('?')[0].substring(1);

  return new Observable<boolean>((obs) => {
    authService.getAccessibleRoutes().pipe(
      // Retry once if the first attempt fails
      retry(1),
      switchMap((res) => {
        // Check if any route in accessibleRoutes matches the sanitizedRoute
        const isAccessible = res?.some((element: string) => sanitizedRoute.startsWith(element));

        if (isAccessible) {
          obs.next(true);  // Allow access if route is accessible
          obs.complete();
          return of(true);
 
        } else {
          obs.next(false); // Deny access if route is not accessible
          obs.complete();
          return of(false);
         }
      }),
      catchError((err) => {
        // Handle error if needed (optional)
        console.error('Error fetching accessible routes:', err);
        obs.next(false);
        obs.complete();
        return of(false);
      })
    ).subscribe();
  });
};
