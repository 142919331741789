/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ExperienceLevel = 'Unknown' | 'Experience' | 'ExperienceType' | 'Module' | 'Task' | 'Action' | 'LearningSupportElement';

export const ExperienceLevel = {
    Unknown: 'Unknown' as ExperienceLevel,
    Experience: 'Experience' as ExperienceLevel,
    ExperienceType: 'ExperienceType' as ExperienceLevel,
    Module: 'Module' as ExperienceLevel,
    Task: 'Task' as ExperienceLevel,
    Action: 'Action' as ExperienceLevel,
    LearningSupportElement: 'LearningSupportElement' as ExperienceLevel
};